<template>
<Box>

  <ErrorRow :error="error" /> 
  <SuccessRow :message="message" /> 

  <Row>
   <Column :width="15">
    <TimesheetList v-if="!isLoading" :start="startComponent" :isAdmin="false" />
   </Column>
  </Row>
  <LoadingRow v-if="isLoading">{{ loadingMessage }} </LoadingRow>
  
</Box> <!-- container -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

//import ConstUtils      from '@/utils/ConstUtils.js'
import ConnectionUtils      from '@/utils/ConnectionUtils.js';
import TimesheetList  from './TimesheetList.vue'
import Catelog         from "@/domain/session/CanadianEnglish.js";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";
import Box from '@/portals/shared/library/box/Box.vue';
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";

export default {
  name: 'portal-inspections',
  components: {
    TimesheetList,
    LoadingRow,
    Box, Row, Column,
    ErrorRow, SuccessRow,
  },
  props: {
  },
  data() {
    return {
      error: null,
      message: null,
      startComponent: false,
      K: Catelog.KEYS,
      C: new Catelog(),
      isLoading: true,
      loadingMessage: "",
      showSessionTimeoutDialog: false,
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                  ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.startComponent = true;
      this.isLoading = false;
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
  },
}
</script>